import { INetworkCore } from "../../../../common/_evm/interfaces/INetworkCore";
import { ChainId } from "@uniswap/sdk";

export const mainnet: INetworkCore = {
  networkCredentials: {
    chainId: ChainId.SKALE,
    name: "SKALE Europa DeFi Hub",
    rpcUrl: "https://mainnet.skalenodes.com/v1/elated-tan-skat",
    currency: "sFUEL",
    explorerUrl: "https://elated-tan-skat.explorer.mainnet.skalenodes.com",
  },
  cgt: "0xf97a23a0E49e5E0028F8Ce89F7be9a9FD5942Cf6",
  whitelist: "",
  router: "",
  multicall: "",
  blocksPerYear: 0,
};

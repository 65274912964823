import classes from "./PoolsPositions.module.css";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { usePositions } from "../../hooks/usePositions";
import { useTonAddress } from "@tonconnect/ui-react";
import { PoolInfo } from "../PoolInfo";
import { useTokenListContext } from "../../../../../contexts/TokenList";

export const PoolsPositions = () => {
  const { tokenList } = useTokenListContext();
  const tonAddress = useTonAddress();
  const { pools, loading } = usePositions(tonAddress, tokenList);

  return loading ? (
    <div style={{ width: "100%" }}>
      <Skeleton height={64} count={3} />
    </div>
  ) : (
    <div className={classes.PoolsBlock}>
      {pools.length > 0 ? (
        <div className={classes.PoolsPosition}>
          {pools
            .filter((pool) => !!pool.userShare)
            .map((pool, idx) => (
              <PoolInfo key={idx} pool={pool} />
            ))}
        </div>
      ) : (
        <p className="p2 card">No liquidity found</p>
      )}
    </div>
  );
};

import { INetworkCore } from "../../../../common/_evm/interfaces/INetworkCore";
import { ChainId } from "@uniswap/sdk";

export const testnet: INetworkCore = {
  networkCredentials: {
    chainId: ChainId.SKALE_TESTNET,
    name: "SKALE Testnet Europa DeFi Hub",
    rpcUrl: "https://testnet.skalenodes.com/v1/juicy-low-small-testnet",
    currency: "sFUEL",
    explorerUrl:
      "https://juicy-low-small-testnet.explorer.testnet.skalenodes.com",
  },
  cgt: "0x715D0aE552FE5B04A0C3ee7078Ba2fbb804Cc2A3",
  whitelist: "0x0D0234C00495CEE1604778bDcDD5d6025223de65",
  router: "0xc0794845aD5141FFBF3c0AfCB6E333f3431C0277",
  multicall: "0xd328457e7628898BC6EBAAc730646498073eA086",
  blocksPerYear: 0,
};

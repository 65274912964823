import classes from "./Banner.module.css";

export const Banner = () => {
  return (
    <div className={classes.Banner}>
      <p>
        🎉 Curio is moving to the Bitcoin blockchain! #RWA on Bitcoin is going
        live soon! 👉{" "}
        <a
          href="https://bitspectrum.capitaldex.exchange"
          target="_blank"
          rel="noreferrer"
        >
          Launch BitSpectrum
        </a>
      </p>
      <p>
        🥳 JAMTON Liquid Staking chain (beta) is already live! 👉{" "}
        <a
          href="https://bridge.capitaldex.exchange?direction=ton-ston"
          target="_blank"
          rel="noreferrer"
        >
          Stake TON to L2TON
        </a>
      </p>
      <p>
        🔥 Capital DEX is already deployed on the TON network and has a Telegram
        Mini App! 👉{" "}
        <a href="https://t.me/CapitalDexBot" target="_blank" rel="noreferrer">
          Launch Capital DEX Mini App
        </a>
      </p>
    </div>
  );
};

import React from "react";
import classes from "./PoolsPosition.module.css";
import { PoolEthInfo } from "../PoolEthInfo";
import { usePoolsInfo } from "../../hooks/usePoolsInfo";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import Skeleton from "react-loading-skeleton";

export const PoolsEthPosition = () => {
  const { address } = useWeb3ModalAccount();
  const { positions, loading } = usePoolsInfo(address);

  return loading ? (
    <div style={{ width: "100%" }}>
      <Skeleton height={64} count={3} />
    </div>
  ) : (
    <div className={classes.PoolsBlock}>
      {positions.length > 0 ? (
        <div className={classes.PoolsPosition}>
          {positions.map((position, idx) => (
            <PoolEthInfo key={idx} position={position} />
          ))}
        </div>
      ) : (
        <p className="p2 card">No liquidity found</p>
      )}
    </div>
  );
};

import classes from "./RemoveLiquidity.module.css";
import {
  BackLink,
  InfoRow,
  SwapIcon,
  Tooltip,
  useModalByName,
  Processing,
  TokenPanel,
  PoolPanel,
  TokensPanel,
} from "@curiodao/capital-dex-ui-kit";
import React, { useState } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { RemoveTransactionModal } from "../RemoveTransactionModal";
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { useAllowance } from "../../../../Tokens";
import { getUniswapToken } from "@curiodao/capital-dex-sdk/evm";
import { usePoolInfoByTokens } from "../../hooks/usePoolInfoByTokens";
import { parseUnits } from "ethers";
import { useNetworkContext } from "../../../../Network";
import { useRemoveLiquidityReturns } from "../../hooks/useRemoveLiquidityReturns";
import { useTokenListContext } from "../../../../../contexts/TokenList";
import { getShowBalance } from "../../../../../common/libs/getShowBalance";
import { IToken } from "@curiodao/capital-dex-sdk";
import Skeleton from "react-loading-skeleton";

interface IRemoveLiquidity {
  tokens: IToken[];
}

export const RemoveEthLiquidity = ({ tokens }: IRemoveLiquidity) => {
  const navigate = useNavigate();
  const { modal, closeModal, changeModal } = useModalByName();
  const [inputFrom, setInputFrom] = useState("");
  const { address } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { open } = useWeb3Modal();
  const { tokenList } = useTokenListContext();
  const { core } = useNetworkContext();

  const { position, loading } = usePoolInfoByTokens(
    address,
    tokens[0],
    tokens[1],
  );
  const { tokenAReturns, tokenBReturns } = useRemoveLiquidityReturns(
    position,
    inputFrom,
  );

  const { approve, isAllowance, isApproving } = useAllowance(
    inputFrom,
    core?.router,
    position?.lpToken,
  );

  return position ? (
    <div className={classNames(classes.Wrapper, "card")}>
      <div className={classes.Headline}>
        <BackLink text="Remove liquidity" onClick={() => navigate(-1)} />
        <Tooltip text="Removing pool tokens converts your position back into underlying tokens at the current rate, proportional to your share of the pool. Accrued fees are included in the amounts you receive." />
      </div>
      <TokensPanel>
        <TokenPanel
          title="Input"
          token0={tokens[0]}
          token1={tokens[1]}
          value={inputFrom}
          setValue={setInputFrom}
          tokenBalance={
            position
              ? getShowBalance(
                  parseUnits(
                    position.userPoolBalance.toSignificant(6),
                    tokens[0].decimals,
                  ),
                )
              : parseUnits("0").toString()
          }
        />
        <PoolPanel
          title="Output"
          value0={tokenAReturns.toFixed(4)}
          value1={tokenBReturns.toFixed(4)}
          tokens={tokens}
        />
      </TokensPanel>
      {tokens[0] &&
        tokens[1] &&
        (loading ? (
          <Skeleton height={51} count={1} />
        ) : (
          <div className="card bordered">
            <InfoRow
              title="Rates"
              value={
                <>
                  1 {tokens[0].symbol} ={" "}
                  {`${position.rates} ${tokens[1].symbol}`}
                  <SwapIcon />
                </>
              }
            />
          </div>
        ))}
      {address ? (
        <button
          className={"btn block"}
          onClick={() => {
            if (!isAllowance) {
              approve(inputFrom);
            } else {
              changeModal("remove");
            }
          }}
          disabled={modal === "remove" || isApproving}
        >
          {modal === "remove" ? (
            <Processing />
          ) : isApproving ? (
            "Approving..."
          ) : (
            "Remove"
          )}
        </button>
      ) : (
        <button className={"btn block"} onClick={() => open()}>
          Connect wallet
        </button>
      )}
      {modal === "remove" &&
        address &&
        walletProvider &&
        tokens[0] &&
        tokens[1] && (
          <RemoveTransactionModal
            token0Amount={tokenAReturns.toFixed(4)}
            token1Amount={tokenBReturns.toFixed(4)}
            token0={tokens[0]}
            token1={tokens[1]}
            tokenA={getUniswapToken(tokens[0], tokenList)}
            tokenB={getUniswapToken(tokens[1], tokenList)}
            walletProvider={walletProvider}
            account={address}
            onCloseButtonClick={() => closeModal()}
            shareToRemove={parseUnits(inputFrom, position.lpToken.decimals)}
            burned={inputFrom}
            rates={position.rates}
          />
        )}
    </div>
  ) : null;
};

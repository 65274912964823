import { JSX } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { SwapPage } from "./pages/swap";
import { AddLiquidityPage } from "./pages/pools/add";
import { FarmsPage } from "./pages/farms";
import { PoolsPage } from "./pages/pools";
import { StakingPage } from "./pages/staking";
import { LandingPage } from "./pages/landing";
import { RemoveLiquidityPage } from "./pages/pools/remove";
import { FarmPage } from "./pages/farms/farm";
import { Layout } from "./modules/Layout";
import { useNetworkContext } from "./modules/Network";
import { HelpPage } from "./pages/help";
import { NotFoundPage } from "./pages/404";

export const App = (): JSX.Element => {
  const { isParachain, isTon } = useNetworkContext();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/" element={<Layout />}>
          <Route
            path="swap"
            element={<SwapPage isTon={isTon} isParachain={isParachain} />}
          />
          <Route path="pools">
            <Route
              path=""
              element={<PoolsPage isTon={isTon} isParachain={isParachain} />}
            />
            <Route></Route>
            <Route
              path="add/:pair?"
              element={
                <AddLiquidityPage isTon={isTon} isParachain={isParachain} />
              }
            />
            <Route
              path="remove/:pair"
              element={
                <RemoveLiquidityPage isTon={isTon} isParachain={isParachain} />
              }
            />
          </Route>
          <Route path="farms">
            <Route
              path=""
              element={<FarmsPage isTon={isTon} isParachain={isParachain} />}
            />
            <Route
              path=":id"
              element={<FarmPage isTon={isTon} isParachain={isParachain} />}
            />
          </Route>
          {isParachain && <Route path="staking" element={<StakingPage />} />}
          {!isParachain && <Route path="help" element={<HelpPage />} />}
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

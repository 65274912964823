import { usePools } from "../../hooks/usePools";
import { PoolInfo } from "../PoolInfo";
import classes from "./PoolsPosition.module.css";
import React from "react";
import Skeleton from "react-loading-skeleton";

export const PoolsPosition = () => {
  const { pools, loading } = usePools();

  return loading ? (
    <div style={{ width: "100%" }}>
      <Skeleton height={64} count={3} />
    </div>
  ) : (
    <div className={classes.PoolsBlock}>
      {pools.length > 0 && pools.find((item) => !!item.userShare) ? (
        <div className={classes.PoolsPosition}>
          {pools.map((pool, idx) => (
            <PoolInfo key={idx} pool={pool} />
          ))}
        </div>
      ) : (
        <p className="p2 card">No liquidity found</p>
      )}
    </div>
  );
};
